import * as React from 'react';
import {
  AccessDeniedPage,
  ITaxonomyResource,
  IUserPermissions,
  IncidentApi,
  ConfigurationApi,
  IncidentsManagementApi,
  LibraryApi,
  TaskApi,
  TaxonomyApi,
  TAXONOMY_PATH_TO_RESOURCE_MAP,
  TAXONOMY_PATH_TO_SUBTYPE_MAP,
  TAXONOMY_RESOURCE_DETAILS,
  TAXONOMY_RESOURCE_PATH,
  TAXONOMY_SUBTYPE,
  UserApi,
  ISettings,
  SettingsApi,
  SETTINGS,
  IssueApi,
  RESPONSE_CODE,
  PageNotFoundComponent,
  TaskCompletePage,
  TestApi,
  TAXONOMY_RESOURCE,
  InitiativesApi
} from '@logicmanager/common';
import { Loader } from 'semantic-ui-react';
import {
  TAXONOMY_PERMISSIONS,
  PERMISSIONS_TO_LINK_NAMES_MAPPING,
  LIBRARY_RESOURCE_PATH,
  TAXONOMY_MANAGE_AUTO_RULES_PERMISSIONS
} from '../constants/constants';

interface IAccessCheckComponentState {
  readonly accessStatus: RESPONSE_CODE;
}

export async function canAccessTask(props: any): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} = await TaskApi.checkTaskById({id: props.match.params.taskId}).request();
    // UrlRequest only returns Response on an error, so if we get an empty (falsey)
    // response that means access checks passed.
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessIncident(props: any): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} =
      await IncidentApi.checkIncidentById({ incidentId: props.match.params.incidentId }).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessReportIncident(props: any): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} =
      await IncidentApi.checkReportIncidentByTestId({ testId: props.match.params.incidentTypeId }).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessIssue(props: any): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} = await IssueApi.checkIssueById(props.match.params.issueId).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessResult(props: any): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} = await TestApi.checkTaskByResultId(props.match.params.resultId).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessConfiguration(): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} =
      await ConfigurationApi.checkConfiguration().request();
    return  result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessIncidentsManagement(): Promise<RESPONSE_CODE> {
  try {
    const result: Response | {} =
      await IncidentsManagementApi.checkIncidentsManagement().request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessLibrary(props: any): Promise<RESPONSE_CODE> {
  try {
    if (Object.values(LIBRARY_RESOURCE_PATH).indexOf(props.resource) === -1) {
      return RESPONSE_CODE.UNAUTHORIZED;
    }
    let result: Response | {};
    if (props.resource === LIBRARY_RESOURCE_PATH.CONTROLS) {
      result = await LibraryApi.checkControlLibrary().request();
    } else {
      result = (!props.elementId)
        ? await LibraryApi.checkLibrary({type: props.resource}).request()
        : await LibraryApi.checkLibraryIndicator({
          type: props.resource,
          id: props.elementId
        }).request();
    }
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessTaxonomy(props: any): Promise<RESPONSE_CODE> {
  try {
    if (Object.values(TAXONOMY_RESOURCE_PATH).indexOf(props.resource) === -1) {
      return RESPONSE_CODE.UNAUTHORIZED;
    }
    const resourceDetails: ITaxonomyResource = TAXONOMY_RESOURCE_DETAILS[TAXONOMY_PATH_TO_RESOURCE_MAP[props.resource]];
    const subtypeQuery: { type?: number } = {};
    if (props.subtype && resourceDetails.hasOwnProperty('subtypes')) {
      const resourceSubtypes: TAXONOMY_SUBTYPE[] = Object.keys(resourceDetails.subtypes);
      if (props.subtype && resourceSubtypes.indexOf(TAXONOMY_PATH_TO_SUBTYPE_MAP[props.subtype]) === -1) {
        return RESPONSE_CODE.UNAUTHORIZED;
      }
      subtypeQuery.type = resourceDetails.subtypes[TAXONOMY_PATH_TO_SUBTYPE_MAP[props.subtype]].typeId;
    } else if (props.subtype) {
      return RESPONSE_CODE.UNAUTHORIZED;
    }
    const result: Response | {} = (!props.elementId)
      ? await TaxonomyApi.checkTaxonomyByResource({resource: resourceDetails.apiPath}).request()
      : await TaxonomyApi.checkTaxonomyByElementId({
        resource: resourceDetails.apiPath,
        id: props.elementId,
        ...subtypeQuery
      }).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessPolicyPortal(props: any): Promise<RESPONSE_CODE> {
  try {
    const settings: ISettings = await SettingsApi.getSettings().request();
    if (settings.POLICY_PORTAL_ENABLED !== '1') {
      return RESPONSE_CODE.UNAUTHORIZED;
    }
    const result: Response | {} = props.elementId
      ? await TaxonomyApi.checkTaxonomyByElementId({
        resource: TAXONOMY_RESOURCE_DETAILS[TAXONOMY_RESOURCE.POLICY_PORTAL].apiPath,
        id: props.elementId,
      }).request()
      : await TaxonomyApi.checkTaxonomyByResource({
        resource: TAXONOMY_RESOURCE_DETAILS[TAXONOMY_RESOURCE.POLICY_PORTAL].apiPath
      }).request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

export async function canAccessPrograms(): Promise<RESPONSE_CODE> {
  try {
    const result: Response =
      await InitiativesApi.checkProgramsAccess().request();
    return result ? (result as Response).status as RESPONSE_CODE : RESPONSE_CODE.OKAY;
  } catch (e) {
    return e.status;
  }
}

/**
 * HoC function to wrap a given component with access checks.
 * Components that cannot be accessed show an Access Denied page instead.
 * A loader is shown until access status is determined.
 * @param Component The component to wrap.
 * @param canAccessComponent Callback for determining access.
 */
export function withAccessCheck(Component: any,
                                canAccessComponent: (props: any) => Promise<RESPONSE_CODE>): React.ComponentType {
  return class extends React.Component<any, IAccessCheckComponentState> {
    private mounted: boolean = false;
    constructor(props: any) {
      super(props);
      this.state = {
        accessStatus: null
      };
    }
    public componentDidMount(): void {
      this.mounted = true;
      this.checkAccess();
    }
    public componentDidUpdate(prevProps: any): void {
      if (this.props.match.url !== prevProps.match.url) {
        this.checkAccess();
      }
    }

    private checkAccess(): void {
      canAccessComponent(this.props).then((responseCode: RESPONSE_CODE) => {
        if (this.mounted) {
          this.setState({ accessStatus: responseCode });
        }
      });
    }

    public componentWillUnmount(): void {
      this.mounted = false;
    }

    public render(): JSX.Element {
      const { accessStatus } = this.state;
      switch (accessStatus) {
        case RESPONSE_CODE.FORBIDDEN:
        case RESPONSE_CODE.UNAUTHORIZED:
          return <AccessDeniedPage />;
        case RESPONSE_CODE.GONE:
          return <TaskCompletePage />;
        case RESPONSE_CODE.NOT_FOUND:
        case RESPONSE_CODE.INTERNAL_SERVER:
          return <PageNotFoundComponent/>;
        case RESPONSE_CODE.OKAY:
          return <Component {...this.props} />;
        default:
          return <Loader />;
      }
    }
  };
}

function getTaxonomyDropdownResourceLinkNames(
  userPermissions: IUserPermissions,
  taxonomyPermissions: typeof TAXONOMY_PERMISSIONS,
  settings: ISettings
): string[] {
  const grantedLinkNames: string[] = [];
  for (const permissionKey of Object.values(taxonomyPermissions)) {
    if (!!+settings[SETTINGS[`AREA_${permissionKey}_ENABLED`]] &&
        (userPermissions[`CAN_VIEW_ALL_${permissionKey}`] ||
          userPermissions[`MANAGE_${permissionKey}`] ||
          userPermissions[`MANAGE_ALL_${permissionKey}`] ||
          userPermissions[TAXONOMY_MANAGE_AUTO_RULES_PERMISSIONS[permissionKey]])) {
      grantedLinkNames.push(...PERMISSIONS_TO_LINK_NAMES_MAPPING[permissionKey]);
    }
  }
  return grantedLinkNames;
}

function getLibraryDropdownResourceLinkNames(userPermissions: IUserPermissions, settings: ISettings): string[] {
  const grantedLinkNames: string[] = [];
  const ERMPlansArea: string = 'ERMPlansArea';

  if (+settings[ERMPlansArea]) {
    const LIBRARY_RISK_ENABLED: string = 'GeneralViewArea';
    const LIBRARY_READINESS_ENABLED: string = 'ReadinessArea';
    const LIBRARY_PERFORMANCE_ENABLED: string = 'PerformanceArea';
    const MANAGE_RISK_IFC: string = 'MANAGE_RISK_IFC';
    const MANAGE_READINESS_IFC: string = 'MANAGE_READINESS_IFC';
    const MANAGE_PERFORMANCE_IFC: string = 'MANAGE_PERFORMANCE_IFC';
    if (+settings[LIBRARY_RISK_ENABLED] && userPermissions[MANAGE_RISK_IFC]) {
      grantedLinkNames.push(LIBRARY_RESOURCE_PATH.RISK);
    }
    if (+settings[LIBRARY_READINESS_ENABLED] && userPermissions[MANAGE_READINESS_IFC]) {
      grantedLinkNames.push(LIBRARY_RESOURCE_PATH.READINESS);
    }
    if (+settings[LIBRARY_PERFORMANCE_ENABLED] && userPermissions[MANAGE_PERFORMANCE_IFC]) {
      grantedLinkNames.push(LIBRARY_RESOURCE_PATH.PERFORMANCE);
    }
  }

  return grantedLinkNames;
}

export async function getAccessPermissions(): Promise<{[permissionType: string]: boolean | string[]}> {
  const REPORT_PORTAL: string = 'CAN_VIEW_REPORT_PORTAL';
  const POLICY_PORTAL: string = 'CAN_VIEW_POLICY_PORTAL';
  const MODIFY_ALL_PLANS: string = 'MODIFY_ALL_PLANS';
  const VIEW_ALL_PLANS: string = 'VIEW_ALL_PLANS';
  const MANAGE_PLANS: string = 'ASSESS'; // Other permissions will be removed and this will be used
  const ERMPlansArea: string = 'ERMPlansArea';
  const INCIDENT_MANAGEMENT_AREA: string = 'SIGN_OFF_TEST_DESIGN';
  const VIEW_CONTROL_LIBRARY: string = 'VIEW_CONTROL_LIBRARY';
  const VIEW_OWNED_PROGRAMS: string = 'VIEW_OWNED_PROGRAMS';
  const MANAGE_OWNED_PROGRAMS: string = 'MANAGE_OWNED_PROGRAMS';
  const VIEW_ALL_PROGRAMS: string = 'VIEW_ALL_PROGRAMS';
  const MANAGE_ALL_PROGRAMS: string = 'MANAGE_ALL_PROGRAMS';
  try {
    const [permissions, settings, canAccessConfigurations]: [IUserPermissions, ISettings, boolean] = await Promise.all([
      UserApi.getUserPermissions().request(),
      SettingsApi.getSettings().request(),
      canAccessConfiguration().then((responseCode: RESPONSE_CODE) => (responseCode === RESPONSE_CODE.OKAY))
    ]);

    return {
      canAccessPlans: (+settings[ERMPlansArea] &&
                        (permissions[MODIFY_ALL_PLANS] || permissions[VIEW_ALL_PLANS] || permissions[MANAGE_PLANS])),
      canAccessTaxonomy: getTaxonomyDropdownResourceLinkNames(permissions, TAXONOMY_PERMISSIONS, settings),
      canAccessReportPortal: permissions[REPORT_PORTAL],
      canAccessLibrary: getLibraryDropdownResourceLinkNames(permissions, settings),
      canAccessConfiguration: canAccessConfigurations,
      canAccessIncidentsManagement: permissions[INCIDENT_MANAGEMENT_AREA],
      canAccessPrograms: (permissions[VIEW_OWNED_PROGRAMS] || permissions[MANAGE_OWNED_PROGRAMS]
        || permissions[VIEW_ALL_PROGRAMS]  || permissions[MANAGE_ALL_PROGRAMS]),
      useHeaderLogo: settings.USE_HEADER_LOGO === '1',
      viewControlLibrary: permissions[VIEW_CONTROL_LIBRARY],
      policyPortalEnabled: settings.POLICY_PORTAL_ENABLED === '1' && permissions[POLICY_PORTAL]
    };
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e, 'Failed to load permissions');
  }
}
