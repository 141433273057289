import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { autobind } from 'core-decorators';
import debounce from 'lodash/debounce';
import { Container, Loader } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import {
  addEventListeners,
  AUTH_METHOD,
  AuthApi,
  dispatchGrowl,
  getTimeout,
  GROWL_HEADERS,
  GROWL_MESSAGES,
  GROWL_TYPE,
  IAuthMethod,
  IAuthTicket,
  ICheckTokenResponse,
  initialAuthCheck,
  LIBRARY_TYPE,
  LM_ACTIVITY_KEY,
  LM_LIBRARY_TREE_KEYS,
  LM_STORAGE_KEY,
  LM_TAXONOMY_TREE_KEY,
  LocalStorage,
  LOGIN_SEARCH_PARAMS,
  LoginForm,
  MODAL_TYPE,
  PageNotFoundComponent,
  removeEventListeners,
  ResetPasswordForm,
  RESPONSE_CODE,
  StandardModal,
  ThirdPartyUtils,
  toast,
  DocumentViewer,
  ToastContainer,
  CONTROL_LIBRARY_TREE_KEY,
  LM_API_VERSION_KEY,
  Report
} from '@logicmanager/common';
import { AppHeader } from '../../components/header';
import { IContentConnectProps } from './content-container';
import * as Actions from '../../actions';
import * as BUILD from '../../../config/config';
import { IAppProps } from '@logicmanager/tasks';
import './content.less';
import {
  canAccessConfiguration,
  canAccessIncident,
  canAccessIncidentsManagement,
  canAccessIssue,
  canAccessLibrary,
  canAccessPolicyPortal,
  canAccessPrograms,
  canAccessReportIncident,
  canAccessResult,
  canAccessTask,
  canAccessTaxonomy,
  withAccessCheck
} from '../../functions';
import {
  APPLICATION_CONSTANTS,
  DEFAULT_BUSINESS_PROCESS_ROUTE,
  DEFAULT_TAXONOMY_ROUTE,
  VALID_INITIATIVES_ROUTE,
  VALID_LIBRARY_ROUTES,
  VALID_POLICY_PORTAL_ROUTE,
  VALID_TAXONOMY_ROUTES
} from '../../constants/constants';
import { IncidentsFacade } from '../../components/incidents';
import { CreateElementFacade } from '../../components/create-element';
import { IssuesFacade } from '../../components/issues';
import { ResultsFacade } from '../../components/results';
import { ErrorBoundary } from '../../components/error-boundary';
import { IContentState } from './interfaces';
import { IpErrorPageComponent } from '../../components/ip-error';
import isNil from 'lodash/isNil';
import { withSentryRouting, Scope, configureScope } from '@sentry/react';
const HomeScreenApp: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import('@logicmanager/home-screen'));
const TaxonomyApp: React.LazyExoticComponent<React.ComponentType> = React.lazy(() => import('@logicmanager/taxonomy'));
const TasksApp: React.LazyExoticComponent<React.ComponentType> = React.lazy(() => import('@logicmanager/tasks'));
const PlansApp: React.LazyExoticComponent<React.ComponentType> = React.lazy(() => import('@logicmanager/plans'));
const IncidentsWebformApp: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import('@logicmanager/incidents-webform'));
const CrashTest: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import('../../components/error-boundary')
    .then((module: any) => ({ default: module.CrashTest }))
  );
const ConfigurationApp: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import('@logicmanager/configuration'));
const SentryRoute: typeof Route = withSentryRouting(Route);
const ProtectedTasksApp: any = withAccessCheck(TasksApp, canAccessTask);
const ProtectedIncidentsApp: any = withAccessCheck(IncidentsFacade, canAccessIncident);
const ProtectedReportIncidentApp: any = withAccessCheck(IncidentsWebformApp, canAccessReportIncident);
const ProtectedTaxonomyApp: any = withAccessCheck(TaxonomyApp, canAccessTaxonomy);
const ProtectedLibraryApp: any = withAccessCheck(TaxonomyApp, canAccessLibrary);
const ProtectedPolicyPortalApp: any = withAccessCheck(TaxonomyApp, canAccessPolicyPortal);
const ProtectedIssuesApp: any = withAccessCheck(IssuesFacade, canAccessIssue);
const ProtectedResultsApp: any = withAccessCheck(ResultsFacade, canAccessResult);
const ProtectedConfigurationApp: any = withAccessCheck(ConfigurationApp, canAccessConfiguration);
const ProtectedIncidentsManagementApp: any = withAccessCheck(ConfigurationApp, canAccessIncidentsManagement);
const ProtectedInitiativesApp: any = withAccessCheck(TaxonomyApp, canAccessPrograms);
// This is a workaround to fix the warning that appears when react suspense and react-router-dom 4.3.1 are used
// together. It is fixed in react-router-dom 5.0.0 but breaks other code.
// Both issues should be fixed in the future versions of react-router-dom 5.*.*
// TODO: watch the updates of react-router-dom and update the library once it is bug free.
const HomeScreenContainer: any = (props: any) => <HomeScreenApp {...props} />;
const PlansAppContainer: any = (props: any) => <PlansApp {...props} />;
const CrashTestContainer: any = () => <CrashTest />;

const ProtectedTasksContainer: any = (props: IAppProps) => (
  <Container className="task-standalone-container">
    <ProtectedTasksApp
      {...props}
      taskId={props.match.params.taskId}
    />
  </Container>
);

const CreateElementFacadeContainer: any = (props: IAppProps) => <CreateElementFacade {...props} />;

const ProtectedIssuesContainer: any = (props: IAppProps) => <ProtectedIssuesApp {...props} />;

const ProtectedIncidentsContainer: any = (props: IAppProps) => (
  <ProtectedIncidentsApp
    {...props}
    incidentId={props.match.params.incidentId}
  />
);

const ProtectedConfigurationContainer: any = (props: IAppProps) => (
  <ProtectedConfigurationApp
    {...props}
  />
);

const ProtectedIncidentsManagementContainer: any = (props: IAppProps) => (
  <ProtectedIncidentsManagementApp
    {...props}
  />
);

const ProtectedReportIncidentContainer: any = (props: IAppProps) => {

  const onClose: () => boolean = () => onCloseReportIncidentApp(props, false);
  return (
    <Container className="report-incident-standalone-container">
      <ProtectedReportIncidentApp
        {...props}
        internal
        testId={props.match.params.incidentTypeId}
        onCloseModal={onClose}
      />
    </Container>
  );
};

const ProtectedResultsContainer: any = (props: IAppProps) => <ProtectedResultsApp {...props} />;

const ProtectedLibraryContainer: any = (props: any) => (
  <ProtectedLibraryApp
    {...props}
    resource={props.match.params.resource}
    elementId={props.match.params.elementId}
  />
);

const ProtectedInitiativesContainer: any = (props: any) => (
  <ProtectedInitiativesApp
    {...props}
  />
);

// TODO: When taxonomy export for IAppProps is corrected, update props here to TaxAppProps
const ProtectedTaxonomyContainer: any = (props: any) => (
  <ProtectedTaxonomyApp
    {...props}
    resource={props.match.params.resource}
    subtype={props.match.params.subtype}
    elementId={props.match.params.elementId}
  />
);

const ProtectedPolicyPortalContainer: any = (props: any) => (
  <ProtectedPolicyPortalApp
    {...props}
    elementId={props.match.params.elementId}
  />
);

const PageNotFoundContainer: any = (props: IAppProps) => (
  <PageNotFoundComponent
    {...props}
  />
);

const TasksModal: any = (props: IAppProps) => {
  const content: JSX.Element = (
    <ProtectedTasksApp
      {...props}
      prev={props.location.state.prev}
      modal={props.location.state.modal === MODAL_TYPE.TASK}
      taskId={props.match.params.taskId}
    />
  );
  return (
    <StandardModal
      modalOpen
      showFullScreen
      content={content}
    />
  );
};

const IssuesModal: any = (props: IAppProps) => {
  const content: JSX.Element = (
    <ProtectedIssuesContainer
      {...props}
      prev={props.location.state.prev}
      modal
    />
  );
  return (
    <StandardModal
      modalOpen
      showFullScreen
      content={content}
    />
  );
};

const ResultsModal: any = (props: IAppProps) => {
  const content: JSX.Element = (
    <ProtectedResultsContainer
      {...props}
      prev={props.location.state.prev}
      modal
    />
  );
  return (
    <StandardModal
      modalOpen
      showFullScreen
      content={content}
    />
  );
};

const onCloseReportIncidentApp: (props: IAppProps, modal: boolean, submit?: boolean) => boolean
  = (props: IAppProps, modal: boolean, submit?: boolean) => {
    if (modal) {
      if (props.location.state.prev.result) {
        props.history.replace({
          pathname: props.location.state.prev.result.pathname,
          search: props.location.state.prev.result.search,
          state: {
            prev: props.location.state.prev.monitor,
            modal: MODAL_TYPE.MONITORING_RESULT,
            submit: !!submit
          }
        });
      } else if (props.location.state.prev.activity) {
        props.history.replace({
          pathname: props.location.state.prev.activity.pathname,
          search: props.location.state.prev.activity.search,
          state: {
            prev: props.location.state.prev.mitigation,
            modal: MODAL_TYPE.TASK,
            submit: !!submit
          }
        });
      } else {
        if (props.location.state.prev.pathname.indexOf('results') !== -1) {
          props.history.replace({
            pathname: props.location.state.prev.pathname,
            search: props.location.state.prev.search,
            state: null
          });
        } else {
          const path: string =
            `${props.history.location.state.prev.pathname}${props.history.location.state.prev.search}`;
          props.history.replace(path, { submit: !!submit });
        }
      }
    } else {
      props.history.replace('/');
    }
    return true;
  };


const ProtectedReportIncidentModal: any = (props: IAppProps) => {
  const onClose: (submit?: boolean) => boolean = (submit?: boolean) => onCloseReportIncidentApp(props, true, submit);
  const content: JSX.Element = (
    <ProtectedReportIncidentApp
      {...props}
      internal
      testId={props.match.params.incidentTypeId}
      onCloseModal={onClose}
      entityTypeId={props.location.state.entityTypeId}
      entityId={props.location.state.entityId}
      modal={props.location.state.modal === MODAL_TYPE.REPORT_INCIDENT}
    />
  );
  return (
    <StandardModal
      modalOpen
      showFullScreen
      content={content}
    />
  );
};

/**
 * Application main router
 */
const ACTIVE_USER_EVENTS: string[] = ['mousedown', 'mousemove', 'keydown'];
// This needs to be the default session timeout (360 minutes) + 5 minutes for the background job
// that removes expired tokens.
const DEFAULT_TIMEOUT: number = 21900000;
const DEBOUNCE_TIME: number = 30000;
const CHECK_STATUS_DELAY: number = 300000;
const LOGOUT_HASH: string = 'logout';
export class Content extends React.Component<IContentConnectProps, IContentState> {
  private activityTimerId: number = null;
  private onActionWithDebounce: (e: any) => void;
  private passwordResetTicket: string;
  private intendedLocation: string;
  private isInternetConnected: boolean = true;
  private isAPIUpdated: boolean = true;
  private toastId: number = null;
  private ipValidationFailed: boolean = false;

  constructor(props: IContentConnectProps) {
    super(props);
    this.onActionWithDebounce = debounce(this.onAction, DEBOUNCE_TIME);
    this.intendedLocation = '/';

    this.state = {
      isSSO: false,
      gettingAuthMethod: false
    };
  }
  public componentDidMount(): void {
    ThirdPartyUtils.initializeSentry(this.props.history);

    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
    initialAuthCheck(this.confirmAuthCheck, this.loginUser, this.setIpValidationFailed);
    this.passwordResetTicket = this.getPasswordResetTicket();
    if (this.passwordResetTicket) {
      this.redirectLoginUrlForSetPassword();
    } else {
      this.isSSOLogin();
    }
  }
  // TODO: once Content has its own route (now it shares it with Login page),
  // we can remove this method. We need componentDidUpdate to make sure that
  // event listeners do not run in LogIn page and pendo is initialized only once.
  public componentDidUpdate(prevProps: IContentConnectProps): void {

    if (this.props.location.pathname + this.props.location.search
      !== prevProps.location.pathname + prevProps.location.search) {
      ReactGA.pageview(this.props.location.pathname + this.props.location.search);
    }

    if (this.props.auth.token && prevProps.auth.token !== this.props.auth.token) {
      addEventListeners(ACTIVE_USER_EVENTS, this.onActionWithDebounce);
      // If other tabs are active, reset the timer
      window.addEventListener('storage', this.onLastActiveChange);
      ThirdPartyUtils.initializeReportingJsApi();
      ThirdPartyUtils.initializePendo();
      ThirdPartyUtils.initializeAPMMonitoring();
    }
    // check if the previous pathname was '/login' to set the intended location when it isn't to the login page
    if (!this.props.auth.token && this.props.location.pathname !== prevProps.location.pathname) {
      this.intendedLocation = prevProps.location.pathname + prevProps.location.search;
    }
  }

  public componentWillUnmount(): void {
    removeEventListeners(ACTIVE_USER_EVENTS, this.onActionWithDebounce);
    window.removeEventListener('storage', this.onLastActiveChange);
    localStorage.removeItem(LM_ACTIVITY_KEY);
  }

  @autobind
  public userActivity(statusAction: (token: IAuthTicket) => Promise<ICheckTokenResponse>): void {
    const auth: IAuthTicket = JSON.parse(localStorage.getItem(LM_STORAGE_KEY));
    if (!auth) {
      // When LogIn page is in a separate component, the event listeners will be removed in componentWillUnmount
      removeEventListeners(ACTIVE_USER_EVENTS, this.onActionWithDebounce);
      window.removeEventListener('storage', this.onLastActiveChange);
      this.makeLogout();
    } else {
      statusAction(auth.token)
        .then((response: ICheckTokenResponse) => {
          if (!this.isInternetConnected) {
            toast.dismiss(this.toastId);
            this.isInternetConnected = true;
            this.toastId = null;
          }
          if (!isNil(response.apiVersion)) {
            if (!isNil(localStorage.getItem(LM_API_VERSION_KEY))) {
              if (JSON.parse(localStorage.getItem(LM_API_VERSION_KEY)) !== response.apiVersion && this.isAPIUpdated) {
                this.isAPIUpdated = false;
                const onClick: () => void = () => {
                  this.isAPIUpdated = true;
                  localStorage.setItem(LM_API_VERSION_KEY, JSON.stringify(response.apiVersion));
                  window.location.reload();
                };
                const growlMessage: JSX.Element = (
                  <>
                    {GROWL_MESSAGES.NEW_API_VERSION_PRE_LINK}
                    <a onClick={onClick}>
                      {GROWL_MESSAGES.NEW_API_VERSION_LINK}
                    </a>
                    {GROWL_MESSAGES.NEW_API_VERSION_POST_LINK}
                  </>
                );
                this.toastId = dispatchGrowl(GROWL_TYPE.INFO,
                                             GROWL_HEADERS.NEW_API_VERSION,
                                             growlMessage).toastId;
              }
            } else {
              localStorage.setItem(LM_API_VERSION_KEY, JSON.stringify(response.apiVersion));
            }
          }
        })
        .catch((response: Response) => {
          if (response.status === RESPONSE_CODE.UNAUTHORIZED) {
            this.makeLogout();
          } else if (!response.ok && this.isInternetConnected) {
            this.toastId = dispatchGrowl(GROWL_TYPE.ERROR,
                                         GROWL_HEADERS.CONNECTION_LOST,
                                         GROWL_MESSAGES.CONNECTION_LOST).toastId;
            this.isInternetConnected = false;
          }
        });
    }
  }
  @autobind
  private onAction(e: any): void {
    this.userActivity(AuthApi.sendHeartbeat);
    this.resetTimer();
    localStorage.setItem(LM_ACTIVITY_KEY, JSON.stringify(Math.random()));
  }
  @autobind
  private resetTimer(): void {
    clearTimeout(this.activityTimerId);
    const timeout: number = getTimeout(LM_STORAGE_KEY) === 0 ? DEFAULT_TIMEOUT
      : getTimeout(LM_STORAGE_KEY) + CHECK_STATUS_DELAY;
    this.activityTimerId = window.setTimeout(this.onIdle, timeout);
  }
  @autobind
  private onIdle(e: any): void {
    this.userActivity(AuthApi.checkToken);
    removeEventListeners(ACTIVE_USER_EVENTS, this.onActionWithDebounce);
  }
  @autobind
  private onLastActiveChange(e: any): void {
    if (e.key === LM_ACTIVITY_KEY) {
      this.resetTimer();
    }
  }
  @autobind
  public logoutUserHandler(): void {
    // changing location to allow unsaved changes handlers to block logout.
    this.props.history.push({ hash: LOGOUT_HASH });
    if (this.props.history.location.hash.endsWith(LOGOUT_HASH)) {
      AuthApi.logoutUserAndReleaseToken().request().catch(this.makeLogout);
      this.makeLogout();
    }
  }

  @autobind
  public setIpValidationFailed(): void {
    this.ipValidationFailed = true;
  }

  @autobind
  protected loginUser(auth: IAuthTicket, viaFormSubmission: boolean = false): void {
    this.props.dispatch(Actions.setAuth(auth));
    configureScope((scope: Scope) => {
      scope.setUser({
        email: auth.email,
        id: auth.userNo.toString()
      });
      scope.setTag('lmDomain', auth.domain);
    });
    // if user logged in via form submission, take them to the url they intended to visit post successful authentication
    if (viaFormSubmission) {
      this.props.history.push(this.intendedLocation);
    }
    // reset password reset ticket to null once the user logs in
    this.passwordResetTicket = null;
  }
  @autobind
  protected makeLogout(): void {
    const logoutUrl: string = this.getLogoutUrl();
    localStorage.removeItem(LM_STORAGE_KEY);
    localStorage.removeItem(LM_API_VERSION_KEY);
    localStorage.removeItem(LM_TAXONOMY_TREE_KEY);
    localStorage.removeItem(LM_LIBRARY_TREE_KEYS[LIBRARY_TYPE.PERFORMANCE]);
    localStorage.removeItem(LM_LIBRARY_TREE_KEYS[LIBRARY_TYPE.READINESS]);
    localStorage.removeItem(LM_LIBRARY_TREE_KEYS[LIBRARY_TYPE.RISK]);
    localStorage.removeItem(CONTROL_LIBRARY_TREE_KEY);
    if (logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      this.props.dispatch(Actions.logoutUser());
      this.props.history.push('/');
    }
  }
  @autobind
  protected confirmAuthCheck(): void {
    this.props.dispatch(Actions.setInitialAuthCheck());
  }
  @autobind
  protected getLogoutUrl(): string {
    try {
      return LocalStorage.getAuthTicketItemFromStorage('logoutUrl');
    } catch (e) {
      return null;
    }
  }

  @autobind
  public getLoginPage(): JSX.Element {
    const loginViaForm: (auth: IAuthTicket) => Promise<void> =
      async (auth: IAuthTicket) => {
        await this.loginUser(auth, true);
      };
    if ((this.state.isSSO && !this.isParamInSearch(LOGIN_SEARCH_PARAMS.ERM)) || this.state.gettingAuthMethod) {
      return null;
    }
    return (
      <div>
        {this.passwordResetTicket && <ResetPasswordForm ticket={this.passwordResetTicket} />}
        <LoginForm
          onSuccess={loginViaForm}
          marketingUrl={BUILD.MARKETING_URL}
          reasonCaptureEnabled={!BUILD.EXCLUSIONS.includes(APPLICATION_CONSTANTS.REASON_FOR_LOGIN)}
          isSSO={this.state.isSSO}
        />
        <ToastContainer />
      </div>
    );
  }

  @autobind
  public getPasswordResetTicket(): string {
    const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
    const ticket: string = (urlSearchParams && urlSearchParams.get('ticket'))
      ? urlSearchParams.get('ticket')
      : null;
    return ticket;
  }

  @autobind
  public isParamInSearch(param: LOGIN_SEARCH_PARAMS): boolean {
    const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams && urlSearchParams.has(param as string);
  }

  @autobind
  public async isSSOLogin(): Promise<boolean> {
    try {
      const authMethod: IAuthMethod = await this.getAuthMethod();
      const sso: boolean = authMethod.method.toLowerCase() === AUTH_METHOD.SSO;
      this.setState({ isSSO: sso });
      return sso;
    } catch (response) {
      if (response.status === RESPONSE_CODE.FORBIDDEN) {
        this.setIpValidationFailed();
      }
      // tslint:disable-next-line
      console.error(response);
    }
  }

  @autobind
  private async getAuthMethod(): Promise<IAuthMethod> {
    this.setState({ gettingAuthMethod: true });
    const authMethod: IAuthMethod = await AuthApi.getMethod().request();
    this.setState({ gettingAuthMethod: false });
    return authMethod;
  }

  @autobind
  public async redirectLoginUrlForSetPassword(): Promise<void> {
    const isSSO: boolean = await this.isSSOLogin();
    const isErmFlag: boolean = this.isParamInSearch(LOGIN_SEARCH_PARAMS.ERM);
    // if we found a ticket in the uri search params (already checked)
    // and we are not already trying to get to the set password modal
    // and we are not an sso env, or are bypassing SSO via the erm search param:
    // - redirect to login with the ticket to show the set password modal.
    if (!window.location.href.includes('login?ticket')
      && (!isSSO || isErmFlag)) {
      const destination: string = isErmFlag
        ? `/login?ticket=${this.passwordResetTicket}&erm`
        : `/login?ticket=${this.passwordResetTicket}`;
      this.props.history.push(destination);
    }
  }

  private renderLibraryRoutes(): JSX.Element[] {
    return VALID_LIBRARY_ROUTES.map((path: string, index: number) =>
      <SentryRoute path={path} component={ProtectedLibraryContainer} key={index} />
    );
  }

  private renderTaxonomyRoutes(): JSX.Element[] {
    const routes: JSX.Element[] = [
      <Redirect from="/taxonomy" exact to={`/taxonomy/${DEFAULT_TAXONOMY_ROUTE}`} key={0} />,
      <Redirect from="/taxonomy/business-processes" exact to={`/taxonomy/${DEFAULT_BUSINESS_PROCESS_ROUTE}`} key={1} />
    ];
    return routes.concat(
      VALID_TAXONOMY_ROUTES.map((path: string, index: number) =>
        <SentryRoute path={path} component={ProtectedTaxonomyContainer} key={index + routes.length} />
      )
    );
  }

  private getModalRouteByModalType(modalType: MODAL_TYPE): JSX.Element {
    switch (modalType) {
      case MODAL_TYPE.REPORT_INCIDENT:
        return <SentryRoute exact path="/incidents/report/:incidentTypeId" render={ProtectedReportIncidentModal} />;
      case MODAL_TYPE.TASK:
        return <SentryRoute exact path="/tasks/:taskId" render={TasksModal} />;
      case MODAL_TYPE.ISSUE:
        return <SentryRoute exact path="/issues/:issueId" render={IssuesModal} />;
      case MODAL_TYPE.MONITORING_RESULT:
        return <SentryRoute exact path="/results/:resultId" render={ResultsModal} />;
      default:
        return null;
    }
  }

  public getRedirectComponents(): JSX.Element {
    return (
      <Redirect
        push
        to={{ pathname: '/login', search: window.location.search }}
      />
    );
  }

  public render(): JSX.Element {
    const location: any = this.props.location;
    const modalType: MODAL_TYPE = (location.state && location.state.prev && location.state.modal)
      ? location.state.modal
      : null;
    if (!this.props.auth.ranInitialAuthCheck) {
      return null;
    }
    if (this.ipValidationFailed) {
      return (
        <IpErrorPageComponent/>
      );
    }
    if (!this.props.auth.token) {
      return (
        <Switch>
          <SentryRoute path="/login" exact render={this.getLoginPage} />
          {this.getRedirectComponents()}
        </Switch>
      );
    }
    return (
      <div>
        <AppHeader
          lastName={this.props.auth.lastName}
          firstName={this.props.auth.firstName}
          onLogout={this.logoutUserHandler}
          pathname={this.props.location.pathname}
        />
        <ErrorBoundary location={window.location.href} isFullScreenError={false}>
          <div className="content">
            <React.Suspense fallback={this.getSuspenseFallback(modalType)}>
              <Switch location={modalType ? location.state.prev : location}>
                <Redirect exact from="/login" to="/" />
                <SentryRoute exact path="/" render={HomeScreenContainer} />
                {this.renderLibraryRoutes()}
                {this.renderTaxonomyRoutes()}
                <SentryRoute path={VALID_POLICY_PORTAL_ROUTE} component={ProtectedPolicyPortalContainer} />
                <SentryRoute path="/incident-management" render={ProtectedIncidentsManagementContainer} />
                <SentryRoute exact path="/tasks/:taskId" render={ProtectedTasksContainer} />
                <SentryRoute exact path="/incidents/:incidentId" component={ProtectedIncidentsContainer} />
                <SentryRoute path="/plans" render={PlansAppContainer} />
                <SentryRoute path={VALID_INITIATIVES_ROUTE} render={ProtectedInitiativesContainer} />
                <SentryRoute path="/incidents/report/:incidentTypeId" render={ProtectedReportIncidentContainer} />
                <SentryRoute path="/create-element/:resource/:resourceId" render={CreateElementFacadeContainer} />
                <SentryRoute path="/issues/:issueId" render={ProtectedIssuesContainer} />
                <SentryRoute path="/results/:resultId" render={ProtectedResultsContainer} />
                <SentryRoute path="/configuration" render={ProtectedConfigurationContainer} />
                <SentryRoute path="/crash-test" render={CrashTestContainer} />
                <SentryRoute path="/document/:documentId/:version?" component={DocumentViewer} />
                <SentryRoute path="/in-app-reports" component={Report} />
                <SentryRoute render={PageNotFoundContainer} />
              </Switch>
              {this.getModalRouteByModalType(modalType)}
            </React.Suspense>
          </div>
          <ToastContainer />
        </ErrorBoundary>
      </div>
    );
  }

  public getSuspenseFallback(modalType: MODAL_TYPE): React.ReactNode {
    const className: string = !!modalType ? 'over-modal' : '';
    return (
      <Loader active className={className} />
    );
  }
}
